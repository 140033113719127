// SAFE CLASS -- BECAUSE TAILWIND JIT CURRENT NOT SUPPORT YET
.absolute {
  position: absolute;
}

.googleAuth {
  cursor: pointer;
}

.link {
  cursor: pointer;
}

.edit {
  position: relative;
}

// .edit:hover {
//   opacity: 50%;
// }

.add-new {
 display: flex;
 justify-content: center;
 align-items: center;
}
.add-new a button{
  padding: 77px 68px;
  font-size: 60px;
  border-radius: 50%;
}

.float-right {
  float: right;
}

.deleteButton {
  background: lightgrey;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: calc(100% - 30px);
    top: 10px;
    cursor: pointer;
}
.deleteButton:hover {
  background: grey;
}

.edit-upload {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.edit-upload:hover {
  background: #00000030;
}

.edit-upload-mini:hover {
  background: #00000030;
}

.edit-upload-mini {
  position: absolute !important;
  top: 0;
  height:100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1;
}

.availabilityCreator {
  display: flex;
  justify-content: space-between;
}

.pickWeek {
  margin-left: 10px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.weekday {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: rgb(237, 237, 237);
  border: 1px solid grey;
}
.weekdayHovered {
  background: rgb(192, 192, 192);
}
.weekday:hover {
  background: rgb(192, 192, 192);
}
.pickAvailability {
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.pickTime{ 
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.hour {
  margin-top: 20px;
  margin-right: 5px;
  margin-left: 5px;
  height: 30px;
  width: 80px;
  border-radius: 5px;
  background: rgb(237, 237, 237);
  border: 1px solid grey;
}
.hourHovered {
  background: rgb(192, 192, 192);
}
.hour:hover {
  background: rgb(192, 192, 192);
}

.nc-SetYourAvailabilityData .CalendarDay__highlighted_calendar {
  position: static !important;
}

.selectedTime {
  text-align: center;
  margin-top: 15px;
}
.available {
  font-style: italic;
  font-size: 12px;
}

.times-available {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.listingSectionSidebar__wrap {
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: auto;
}

body:-webkit-scrollbar {
  display: none;
}
.less-margin {
  margin: 0 0;
}